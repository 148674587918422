import type { ItemManagementDetails } from '@/interfaces/inventory/inventoryItemManagementDetails'
import type { UserInventoryItem } from '@/interfaces/inventory/inventoryItemResults'
import type { InventoryItem, ProductDetailsResults } from '@/interfaces/item/productDetailsResult'
import type {
  MatchingInventoryItemsByOwnerResponse,
  MatchingInventoryItemsRequest,
  MatchingInventoryItemsResponse,
} from '@/interfaces/inventory/inventoryApiRequest'

import { useQuery } from '@tanstack/react-query'
import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import useItemManagementDetails from '@/hooks/data/useItemManagementDetails'
import useInventoryItem from '@/hooks/data/useInventoryItem'
import { getItemState, clientAPIRequest } from '@/helpers'
import { RequestMethods } from '@/interfaces/api/requestMethods'

type ItemDetailsContext = {
  item?: InventoryItem
  setItem: Dispatch<SetStateAction<InventoryItem | undefined>>
  userInventoryItem?: UserInventoryItem
  productDetailsByItemId?: ProductDetailsResults
  setProductDetailsByItemId: Dispatch<SetStateAction<ProductDetailsResults | undefined>>
  isItemOwned: boolean
  isItemForSale: boolean
  managementDetails?: ItemManagementDetails
  matchingItemsForSale?: MatchingInventoryItemsResponse
  refetchMatchingItemsForSale: () => void
  isLoadingMatchingItemsForSale: boolean
  ownedMatchingItemsForSale?: MatchingInventoryItemsByOwnerResponse
  refetchOwnedMatchingItemsForSale: () => void
  isLoadingOwnedMatchingItemsForSale: boolean
}

const itemDetailsContext = createContext<ItemDetailsContext>({
  item: undefined,
  setItem: () => {},
  userInventoryItem: undefined,
  productDetailsByItemId: undefined,
  setProductDetailsByItemId: () => {},
  isItemOwned: false,
  isItemForSale: true,
  managementDetails: {} as ItemManagementDetails,
  matchingItemsForSale: undefined,
  refetchMatchingItemsForSale: () => {},
  isLoadingMatchingItemsForSale: false,
  ownedMatchingItemsForSale: undefined,
  refetchOwnedMatchingItemsForSale: () => {},
  isLoadingOwnedMatchingItemsForSale: false,
})

export default function ItemDetailsProvider({ children }: PropsWithChildren): JSX.Element {
  const [item, setItem] = useState<InventoryItem | undefined>(undefined)
  const [productDetailsByItemId, setProductDetailsByItemId] = useState<ProductDetailsResults | undefined>(undefined)
  const { isItemOwned, isItemForSale } = getItemState(item)
  const { managementDetails } = useItemManagementDetails(isItemOwned, item?.itemId)
  const { userInventoryItem } = useInventoryItem(isItemOwned, item?.itemId)

  const getMatchingItemsForSale = useCallback(async () => {
    return clientAPIRequest<MatchingInventoryItemsResponse, MatchingInventoryItemsRequest>(
      '/api/inventory/matchingItemsForSale',
      RequestMethods.GET,
      {
        itemId: item?.itemId ?? 0,
      }
    )
  }, [item?.itemId])

  const getOwnedMatchingItemsForSale = useCallback(async () => {
    return clientAPIRequest<MatchingInventoryItemsByOwnerResponse, MatchingInventoryItemsRequest>(
      '/api/inventory/ownedMatchingItems',
      RequestMethods.GET,
      {
        itemId: item?.itemId ?? 0,
      }
    )
  }, [item?.itemId])

  const {
    data: matchingItemsForSale,
    refetch: refetchMatchingItemsForSale,
    isLoading: isLoadingMatchingItemsForSale,
  } = useQuery({
    queryKey: ['matchingItemsForSale', item?.itemId, item?.price],
    queryFn: getMatchingItemsForSale,
    enabled: !!item?.itemId,
  })

  const {
    data: ownedMatchingItemsForSale,
    refetch: refetchOwnedMatchingItemsForSale,
    isLoading: isLoadingOwnedMatchingItemsForSale,
  } = useQuery({
    queryKey: ['ownedMatchingItemsForSale', item?.itemId, item?.price],
    queryFn: getOwnedMatchingItemsForSale,
    enabled: !!item?.itemId,
  })

  const itemDetailsValue = useMemo(
    () => ({
      item,
      setItem,
      userInventoryItem,
      productDetailsByItemId,
      setProductDetailsByItemId,
      isItemOwned,
      isItemForSale,
      managementDetails,
      matchingItemsForSale,
      refetchMatchingItemsForSale,
      isLoadingMatchingItemsForSale,
      ownedMatchingItemsForSale,
      refetchOwnedMatchingItemsForSale,
      isLoadingOwnedMatchingItemsForSale,
    }),
    [
      item,
      userInventoryItem,
      productDetailsByItemId,
      isItemOwned,
      isItemForSale,
      managementDetails,
      matchingItemsForSale,
      refetchMatchingItemsForSale,
      isLoadingMatchingItemsForSale,
      ownedMatchingItemsForSale,
      refetchOwnedMatchingItemsForSale,
      isLoadingOwnedMatchingItemsForSale,
    ]
  )

  return <itemDetailsContext.Provider value={itemDetailsValue}>{children}</itemDetailsContext.Provider>
}

export const useItemDetailsProvider = (): ItemDetailsContext => useContext(itemDetailsContext)
