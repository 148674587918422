import type { NextComponentType, NextPageContext } from 'next'
import type { AppProps } from 'next/app'
import type { AuthPageProp } from '@/interfaces/auth/auth'

import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { CacheProvider, type EmotionCache } from '@emotion/react'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

import options from '@/lib/newRelic/newRelic'
import theme from '@/themes/comcTheme'
import createEmotionCache from '@/themes/createEmotionCache'
import AppContextProvider from '@/components/contextProviders/AppContextProvider'
import PageContent from '@/components/layout/PageContent'
import { trackPageview } from '@/lib/googleAnalytics'
import PixelScript from '@/lib/facebookPixel/PixelScript'
import * as fbq from '@/lib/facebookPixel/fpixel'
import '@/styles/globals.css'
import '@/styles/swiper.css'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: NextComponentType<NextPageContext, any, any> & AuthPageProp
}

const App = (props: MyAppProps) => {
  const { emotionCache = clientSideEmotionCache, pageProps } = props
  const router = useRouter()
  useEffect(() => {
    if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_APP_ENV === 'production') {
      ;(async () => {
        const { BrowserAgent } = await import('@newrelic/browser-agent/loaders/browser-agent')

        // eslint-disable-next-line no-new
        new BrowserAgent(options)
      })()
    }

    const handleRouteChange = (url: string) => {
      fbq.pageview()

      if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
        trackPageview(url)
      }
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
  return (
    <>
      <PixelScript />
      <CacheProvider value={emotionCache}>
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <AppContextProvider session={pageProps.session}>
            <PageContent {...props} />
          </AppContextProvider>
        </ThemeProvider>
      </CacheProvider>
    </>
  )
}

/**
 * LaunchDarkly Initialization
 */
interface LDProps extends JSX.IntrinsicAttributes, MyAppProps {}

export default withLDProvider<LDProps>({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_SIDE_ID,
  options: {
    // Performance options
    fetchGoals: false,
    diagnosticOptOut: true,
    sendEvents: false,
    disableSyncEventPost: true,
    sendLDHeaders: false,
  },
  reactOptions: {
    sendEventsOnFlagRead: false,
  },
})(App)
