const options = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net'] },
  }, // NREUM.init
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: process.env.NEXT_PUBLIC_NEW_RELIC_LICENSE_KEY,
    applicationID: process.env.NEXT_PUBLIC_NEW_RELIC_APP_ID,
    sa: 1,
  }, // NREUM.info
  loader_config: {
    accountID: process.env.NEXT_PUBLIC_NEW_RELIC_ACCOUNT_ID,
    trustKey: process.env.NEXT_PUBLIC_NEW_RELIC_ACCOUNT_ID,
    agentID: process.env.NEXT_PUBLIC_NEW_RELIC_APP_ID,
    licenseKey: process.env.NEXT_PUBLIC_NEW_RELIC_LICENSE_KEY,
    applicationID: process.env.NEXT_PUBLIC_NEW_RELIC_APP_ID,
  }, // NREUM.loader_config
}

export default options
